<template>
    <div>
        <div >
            <!-- Header container -->

            <div class="py-4">
              <div class="d-inline-block ">
                <h3 class="mb-0 text-white">Taxonómia</h3>
                <h2 class="" v-if="contenttype.title">{{contenttype.title}}</h2>
                <h2 class="" v-else>Nový slovník</h2></div>
                <div class="text-right setup-submenu" style="float:right"  v-if="contenttype.id">
                    <a-dropdown>
                        <a-menu slot="overlay">
                            <a-menu-item key="1"><router-link :to="'/taxonomy-items/' + contenttype.id"><i class="fal fa-list-alt"></i> Položky taxonómie</router-link></a-menu-item>
                            <a-menu-item key="2"><router-link :to="'/add-taxonomy-item/' + contenttype.id"><i class="fal fa-plus-square"></i> Nová položka</router-link></a-menu-item>
                        </a-menu>
                        <a-button style="margin-left: 8px" type="primary" size="large"> <i class="fal fa-cog"></i> </a-button>
                    </a-dropdown>
                </div>
            </div>
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">

                </div>
                <div class="text-right" style="float:right">
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Nastavenia</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label>Názov</label>
                                            <a-input alternative=""
                                                        label="Názov"
                                                        input-classes="form-control-alternative"
                                                        v-model="contenttype.title"
                                            />
                                        </div>
                                        <div class="col-lg-12">
                                            <label>Slug</label>
                                            <a-input alternative=""
                                                        label="Slug"
                                                        input-classes="form-control-alternative"
                                                        v-model="contenttype.slug"
                                            />
                                        </div>
                                        <div class="col-lg-12">
                                            <label>URL slovníka</label>
                                            <a-input alternative=""
                                                        label="URL slovníka"
                                                        input-classes="form-control-alternative"
                                                        v-model="contenttype.url"
                                            />
                                        </div>
                                        <div class="col-lg-12">
                                            <label>Obrázok</label>
                                            <image-upload :data="contenttype.image" label="Obrázok" dat="image"  @changed="(e) => onChildChange(e,'image')"></image-upload>                                             </a-form-item>

                                        </div>
                                    </div>
                                </div>


                                <!-- Description -->
                                <div class="text-right">
                                    <button class="btn btn-primary">Save</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
                <div class="col-xl-6 order-xl-1">
                    <a-modal :title="'Nové pole'" centered  v-model="addFieldModal" v-if="addFieldModal" @ok="handleNewFieldSubmit">
                        <div class="additional-setup">
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <a-input placeholder="Názov poľa" v-model="newField.title"></a-input>
                                    </div>
                                    <div class="col-md-12">
                                        <a-input placeholder="Slug" v-model="newField.slug"></a-input>
                                    </div>
                                    <div class="col-md-12" v-if="!parseInt(newField.id)">
                                        <a-select defaultValue="1" v-model="newField.type" @change="handleChange" :options="this.types" class="w-100"/>
                                    </div>

                                    <template v-if="newField.type === '8' && !parseInt(newField.id)">
                                        <div>
                                            <h6 class="heading-small text-muted mb-4">Typ obsahu</h6>

                                            <a-radio-group v-decorator="['radio-group']" v-model="newField.content_type">
                                                <a-form-item :label="k.title" :key="k" :label-col="{ span: 22 }" :wrapper-col="{ span: 2 }" v-for="k in contenttypes">
                                                    <a-radio class="mb-3" :value="k.id" />
                                                </a-form-item>
                                            </a-radio-group>

                                        </div>
                                    </template>
                                    <template v-if="newField.type === '12' && !parseInt(newField.id)">
                                        <div>
                                            <h6 class="heading-small text-muted mb-4">Slovníky taxonómie</h6>

                                            <a-radio-group v-decorator="['radio-group']" v-model="newField.content_type">
                                                <a-form-item :label="k.title" :key="k" :label-col="{ span: 22 }" :wrapper-col="{ span: 2 }" v-for="k in taxonomies">
                                                    <a-radio class="mb-3" :value="k.id" />
                                                </a-form-item>
                                            </a-radio-group>
                                            <h6 class="heading-small text-muted mb-4">Multi pole</h6>
                                            <a-switch @change="onChange" />
                                        </div>
                                    </template>
                                    <div class="col-md-12 mt-1" v-if="newField.type === '17' && !parseInt(newField.id)">
                                        <a-form-item
                                                v-for="(k, index) in form.getFieldValue('keys')"
                                                :key="k"
                                                v-bind="formItemLayout"
                                                :required="false"

                                        >
                                            <a-input  v-model="newField.fieldcolection.title[k]" class="w-100" placeholder="Názov"/>
                                            <a-select defaultValue="1" :options="types" class="w-100"  v-model="newField.fieldcolection.type[k]" placeholder="Typ poľa"/>

                                            <a-icon
                                                    v-if="form.getFieldValue('keys').length > 1"
                                                    class="dynamic-delete-button"
                                                    type="minus-circle-o"
                                                    :disabled="form.getFieldValue('keys').length === 1"
                                                    @click="() => remove(k)"
                                            />
                                        </a-form-item>
                                        <a-form-item>
                                            <a-button type="dashed" style="width: 60%; float:left" @click="add">
                                                <a-icon type="plus" /> Pridaj pole
                                            </a-button>
                                        </a-form-item>
                                    </div>
                                    <div class="col-md-6" v-if="newField.type === '2' && !parseInt(newField.id)">
                                        <a-form-item
                                                v-for="(k, index) in form.getFieldValue('keys')"
                                                :key="k"
                                                v-bind="formItemLayout"
                                                :required="false"

                                        >
                                            <a-input  v-model="newField.options[k]"
                                                      v-decorator="[
          `options[${k}]`,
          {
            validateTrigger: ['change', 'blur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Please input title or delete this field.',
              },
            ],
          },
        ]"
                                                      placeholder="Title"

                                            />

                                            <a-icon
                                                    v-if="form.getFieldValue('keys').length > 1"
                                                    class="dynamic-delete-button"
                                                    type="minus-circle-o"
                                                    :disabled="form.getFieldValue('keys').length === 1"
                                                    @click="() => remove(k)"
                                            />
                                        </a-form-item>
                                        <a-form-item v-bind="formItemLayoutWithOutLabel">
                                            <a-button type="dashed" style="width: 60%; float:left" @click="add">
                                                <a-icon type="plus" /> Pridať
                                            </a-button>
                                        </a-form-item>
                                    </div>
                                </div>
                                <a-checkbox class="mb-3 mt-4" v-model="newField.multibool" >Multirozmerné pole</a-checkbox>
                                <a-checkbox class="mb-3 mt-4" v-model="newField.translatablebool" >Samostatne preložiteľné pole</a-checkbox>
                                <a-checkbox class="mb-3 mt-4" v-model="newField.independentbool" >Jazykovo nezávislá položka</a-checkbox>
                            </div>
                        </div>
                    </a-modal>

                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Polia</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <a-button type="primary"  @click="openFieldModal"><i class="far fa-plus-square"></i> Pridať pole</a-button>
                                </div>
                            </div>
                        </div>



                        <div class="table-responsive ">
                            <table class="  ant-table-body" style="font-size:14px;">
                                <tr>
                                    <th></th>
                                    <th>Názov poľa</th>
                                    <th>Typ poľa</th>
                                    <th>Slug</th>
                                    <th></th>
                                </tr>
                                <draggable @change="updateOrderFields" v-model="contenttype.fields" tag="tbody" class="ant-table-tbody" handle=".handle">
                                    <tr v-for="item in contenttype.fields" :key="item.id" class="ant-table-row ant-table-row-level-0">
                                        <td scope="row" style="width:30px;"> <a class="handle"><i class="fa fa-expand-arrows-alt"></i></a></td>
                                        <td>{{ item.title }}</td>
                                        <td>{{ types[item.type-1].label }}</td>
                                        <td>{{ item.slug }}</td>
                                        <td style="min-width:200px;text-align: right;">
                                            <a-button type="secondary"  class="float-left" icon="fas fa-pencil-alt" @click="openFieldModal(item)" textColor="default" title="Zmazať">Uprav</a-button>
                                            <a-popconfirm placement="topRight"
                                                          @confirm="confirm(item.id,item.weight)"
                                                          @cancel="cancel"
                                                          okText="Áno"
                                                          cancelText="Nie">
                                                <template slot="title">
                                                    <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                                    Položka bude trvalo odstránená z vašej stránky.
                                                </template>
                                                <a-button type="secondary"  icon="fas fa-trash" textColor="default" title="Zmazať">Zmazať</a-button>
                                            </a-popconfirm>
                                        </td>
                                    </tr>
                                </draggable></table>
                        </div>

                    </a-card>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-xl-6 order-xl-1" v-if="contenttype.blocks">
                    <a-card shadow type="secondary">
                        <draggable @change="updateBlocks" v-bind="dragOptions" v-model="contenttype.blocks" group="newcomponents" @start="drag=true" @end="drag=false" handle=".handle">
                            <transition-group>
                                <template v-for="(child, index) in contenttype.blocks">
                                    <div :key="':' + index + child.id" class="hideimages element"><span>
                    <a class="handle"><i class="fa fa-expand-arrows-alt"></i></a><a><em >{{child.component}} </em><b v-if="child.des">{{child.des | truncate(30)}} </b></a>

                                        <a-button type="secondary" v-if="child.id > 0" class="add-button edit-button" :href="'/block/' + child.id" shape="round" icon="edit" :size="size">Upraviť</a-button>
                                    <a-button type="secondary" v-else class="add-button edit-button" :href="'/block/' + child.showon" shape="round" icon="edit" :size="size">Upraviť</a-button>
                                        </span>
                                    </div>
                                </template>
                            </transition-group>
                        </draggable>
                        <div class="text-right">
                            <button class="btn btn-primary" @click="saveReorder">Save</button>
                        </div>  </a-card>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import Image from "./content/Image";
    import FormItem from 'ant-design-vue'
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import {Popconfirm} from "ant-design-vue"
    import draggable from "vuedraggable";

    Vue.use(FormItem);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Typ poľa',
            dataIndex: 'type',
            scopedSlots: {customRender: 'cttype'},
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];
    let id = 0;
    export default {
        name: 'taxonomy',
        components: {AFormItem, "a-popconfirm": Popconfirm,draggable,'image-upload' : Image},
        props: ['id'],

        data() {
            return {
                data: [],
                colorsto: JSON.parse(localStorage.colors),
                newField: {
                    title : null,
                    type : null,
                    fieldcolection: {'title':[],'type':[]},
                    multi: false,
                    multibool: false,
                    independentbool: false,
                    translatablebool: false,
                    options : [],
                    slug : null,
                    content_id : this.id
                },
                loading: false,
                addFieldModal:false,
                columns,
                types: [
                    {"value" : '1' ,"label" : 'Text'},
                    {"value" : '2' ,"label" : 'Výberovník'},
                    {"value" : '3' ,"label" : 'Obrázok'},
                    {"value" : '4' ,"label" : 'Dlhý text'},
                    {"value" : '5' ,"label" : 'Celé číslo'},
                    {"value" : '6' ,"label" : 'Desatiné číslo'},
                    {"value" : '7' ,"label" : 'Logická hodnota Áno/Nie'},
                    {"value" : '8' ,"label" : 'Priradenie pod typ obsahu'},
                    {"value" : '9' ,"label" : 'Dynamická hodnota'},
                    {"value" : '10' ,"label" : 'Builder'},
                    {"value" : '11' ,"label" : 'Galéria obrázkov'},
                    {"value" : '12' ,"label" : 'Priradenie pod taxonómiu'},
                    {"value" : '13' ,"label" : 'Priradenie pod taxonómiu s násobkom'},
                    {"value" : '14' ,"label" : 'Dátum'},
                    {"value" : '15' ,"label" : 'Multipole - vlastnosti'},
                    {"value" : '16' ,"label" : 'Dátum od - do'},
                    {"value" : '17' ,"label" : 'Multipole'},
                    {"value" : '18' ,"label" : 'Ikona'},
                    {"value" : '19' ,"label" : 'Súbor'},
                    {"value" : '20' ,"label" : 'Farba'},
                ],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 20, offset: 4 },
                    },
                },
                head: authHeader(),
                send: API_URL,
                contenttypes: [],
                productCat: {},
                contenttypeId: {
                    uid: this.id
                },
                contenttype: {
                    title: '',
                    type: '',
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        },
        methods: {
            openFieldModal(idd = false){
                if(parseInt(idd.id) > 0)
                {
                    this.newField = idd;
                }
                else {
                    this.newField = {title : null,type : null, blocks: [],options : [], fieldcolection: {'title':[],'type':[]}, multi: false, slug : null,content_id : this.id };
                }
                this.addFieldModal = true;
            },
            confirm(e) {
                dataService.axiosDelete("taxonomyfields", e).then(results => {
                    if (results) {
                        this.contenttype.fields.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            cancel() {
            },
            handleChange(value) {
                console.log(`selected ${value}`);
            },
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },

            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            onChildChange (value,slug) {
                console.log(value);
                this.contenttype.image = JSON.stringify(value); // someValue
            },
            handleSubmit() {
                let $contenttype_data = this.contenttype;
                dataService.axiosPost(this.$options.name, $contenttype_data).then(results => {

                    if(results.data.message)
                    {this.contenttype = []; }

                });
            },
            onChange(checked) {
                this.newField.multi = checked;
            },
            updateOrderFields()
            {

                console.log(this.contenttype.fields);
                dataService.axiosPost('reorderfields/' + this.id, this.contenttype.fields).then(results => {

                });
            },
             saveReorder()
            {
                dataService.axiosPost('reorderblockstax/' + this.id, this.contenttype.blocks).then(results => {

                });
            },
            handleNewFieldSubmit()
            {  this.addFieldModal = false;

                this.newField.multi = this.newField.multibool;
                this.newField.independent = this.newField.independentbool;
                this.newField.translatable = this.newField.translatablebool;
                let $contenttype_data = this.newField;
                dataService.axiosPost(this.$options.name+"/newfield", $contenttype_data).then(results => {
                    if(results.data.data)
                    {
                        this.contenttype.fields = results.data.data.fields;
                    }
                });
            },
        },
        mounted() {
            if (!isNaN(this.id)) {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.contenttype = results;
                });
            }

            dataService.axiosFetch('taxonomies').then(results => {
                this.taxonomies = results;
                this.loading = false;
            });

            dataService.axiosFetch('taxonomyitems',"product_category").then(results => {

                var roleops = [];
                roleops.push({"value" : '0' , "label" : 'Nie je produkt'});
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                this.productCat = roleops;
                this.loading = false;
            });



            dataService.axiosFetch("contenttypes").then(results => {
                this.contenttypes = results;
                this.loading = false;
            });


        }
    };
</script>

<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .element
    {display:block;width:100%; border-top-left-radius: 5px;border-top-right-radius:5px;overflow:hidden;}

    .element
    {
        padding:0;
        border-bottom:1px solid #fff;
        border-radius:0;
    }
    .manager
    {width:800px}
    .top-holder
    {width:100%;border:1px solid #eceff3; padding:15px   }
    .element-image
    {width:100%; border-bottom:1px solid #eceff3; padding-bottom:10px;margin-bottom:10px;}

    .element .handle
    {font-size:22px;padding:5px}
    .element span
    {width:100%; display:block;color: #33a6ba;  text-align:left; min-height:30px; padding-left:5px; font-size:14px; background-color: #a3e4ff;}
    .edit-button
    {float:right; margin:0; margin-left:2px;border-radius:0px; background-color: rgba(0, 0, 0, 0.04); border: 0; color:#fff;
    }
    .ant-select-selection--single
    {width:100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;}

</style>
